<template>
  <main class="pb-10">
    <UiFixedHeader always-visible>
      {{ category?.name }}
    </UiFixedHeader>
    <div class="sticky top-13 z-20 bg-primary md:top-0 lg:pb-4">
      <UiContainer class="mb-3 hidden items-center md:flex">
        <UiTitle severity="h1" class="!text-base md:!text-3xl">{{ category?.name }}</UiTitle>
      </UiContainer>
      <FilterSearch :page-size="PAGE_SIZE" @change="onFiltersChange" />
    </div>
    <UiContainer>
      <section class="relative flex w-full flex-col">
        <CategoryResultsMasonry :value="category" :loading="!categoryInitialized" />
        <UiEternalLoading
          v-if="!allEventsLoaded"
          :handler="loadMoreEvents"
          :all-loaded="allEventsLoaded"
        />
        <UiScrollUpButton class="!sticky bottom-10 z-20 ml-auto mr-0" />
      </section>
    </UiContainer>
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onBeforeMount } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import { CategoryResultsMasonry } from '@/3_widgets/Category'
import { FilterSearch } from '@/4_features/Search'
import { type CategoryFilters, useCategory } from '@/5_entities/Category'
import {
  UiContainer,
  UiEternalLoading,
  UiFixedHeader,
  UiScrollUpButton,
  UiTitle
} from '@/6_shared/ui'

const route = useRoute()
const PAGE_SIZE = 24
let filters: CategoryFilters = {
  pagination: { pageSize: PAGE_SIZE, currentPage: 1 },
  filter: {}
}
const onFiltersChange = (newFilters: CategoryFilters) => {
  filters = newFilters
  loadCategory(Number(route.params.id))
}

const { category, categoryInitialized, allEventsLoaded } = storeToRefs(useCategory())
const { getCategory, loadNextEventsPage } = useCategory()

const loadCategory = (id: number) => getCategory(id, filters)
const loadMoreEvents = () => loadNextEventsPage(filters)

onBeforeMount(() => loadCategory(Number(route.params.id)))

onBeforeRouteUpdate(({ params: { id: toId } }, { params: { id: fromId } }) => {
  if (fromId !== toId) {
    loadCategory(Number(toId as string))
  }
})
</script>
